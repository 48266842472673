<template>
<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
  <path d="m14.8 8 1.2 1.2-6.8 6.8-1.2-1.2zm-10.8-4h16c1.11 0 2 .89 2 2v4c-1.1 0-2 .9-2 2 0
  1.11.9 2 2 2v4c0 1.11-.89 2-2 2h-16c-1.1 0-2-.89-2-2v-4c1.11 0
  2-.89 2-2 0-1.1-.89-2-2-2v-4c0-1.11.9-2 2-2m0 2v2.54c1.24.72 2 2.03 2 3.46s-.76 2.75-2
  3.46v2.54h16v-2.54c-1.24-.71-2-2.03-2-3.46s.76-2.74 2-3.46v-2.54zm5.5 2c.83 0 1.5.67
  1.5 1.5s-.67 1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5m5 5c.83 0 1.5.67 1.5 1.5s-.67
  1.5-1.5 1.5-1.5-.67-1.5-1.5.67-1.5 1.5-1.5z" fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ticketPercent extends Vue {}
</script>
