<template>
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0.739746C7.28625 0.739746 6.945 0.752246 5.87875 0.799746C4.8125 0.849746 4.08625
 1.01725 3.45 1.26475C2.78262 1.51577 2.17811 1.90953 1.67875 2.4185C1.1701 2.91812 0.776384
  3.52255 0.525 4.18975C0.2775 4.82475 0.10875 5.55225 0.06 6.61475C0.0125 7.6835 0 8.0235
   0 10.741C0 13.456 0.0125 13.796 0.06 14.8622C0.11 15.9272 0.2775 16.6535 0.525 17.2897C0.78125
    17.9472 1.1225 18.5047 1.67875 19.061C2.23375 19.6172 2.79125 19.9597 3.44875 20.2147C4.08625
     20.4622 4.81125 20.631 5.87625 20.6797C6.94375 20.7272 7.28375 20.7397 10 20.7397C12.7163
      20.7397 13.055 20.7272 14.1225 20.6797C15.1863 20.6297 15.915 20.4622 16.5513 20.2147C17.2182
       19.9636 17.8223 19.5698 18.3212 19.061C18.8775 18.5047 19.2188 17.9472 19.475 17.2897C19.7213
        16.6535 19.89 15.9272 19.94 14.8622C19.9875 13.796 20 13.456 20 10.7397C20 8.0235 19.9875
        7.6835 19.94 6.616C19.89 5.55225 19.7213 4.82475 19.475 4.18975C19.2237 3.52253 18.8299
        2.91809 18.3212 2.4185C17.822 1.90934 17.2175 1.51555 16.55 1.26475C15.9125 1.01725
        15.185 0.848496 14.1213 0.799746C13.0538 0.752246 12.715 0.739746 9.9975
        0.739746H10.0013H10ZM9.10375 2.54225H10.0013C12.6713 2.54225 12.9875 2.551
        14.0413 2.59975C15.0162 2.6435 15.5462 2.80725 15.8988 2.9435C16.365 3.12475
        16.6987 3.34225 17.0487 3.69225C17.3988 4.04225 17.615 4.37475 17.7963 4.84225C17.9338
        5.1935 18.0963 5.7235 18.14 6.6985C18.1887 7.75225 18.1987 8.0685 18.1987 10.7372C18.1987
        13.406 18.1887 13.7235 18.14 14.7772C18.0963 15.7522 17.9325 16.281 17.7963 16.6335C17.6359
        17.0677 17.38 17.4603 17.0475 17.7822C16.6975 18.1322 16.365 18.3485 15.8975
        18.5297C15.5475 18.6672 15.0175 18.8297 14.0413 18.8747C12.9875 18.9222 12.6713 18.9335
        10.0013 18.9335C7.33125 18.9335 7.01375 18.9222 5.96 18.8747C4.985 18.8297 4.45625 18.6672
        4.10375 18.5297C3.66937 18.3696 3.27641 18.1142 2.95375 17.7822C2.62094 17.4598 2.36465
        17.0668 2.20375 16.6322C2.0675 16.281 1.90375 15.751 1.86 14.776C1.8125 13.7222 1.8025
        13.406 1.8025 10.7347C1.8025 8.06475 1.8125 7.74975 1.86 6.696C1.905 5.721 2.0675 5.191
        2.205 4.8385C2.38625 4.37225 2.60375 4.0385 2.95375 3.6885C3.30375 3.3385 3.63625 3.12225
        4.10375 2.941C4.45625 2.8035 4.985 2.641 5.96 2.596C6.8825 2.5535 7.24 2.541 9.10375
        2.53975V2.54225ZM15.3387 4.20225C15.1812 4.20225 15.0251 4.23329 14.8795 4.29359C14.7339
        4.3539 14.6017 4.44229 14.4902 4.55372C14.3788 4.66515 14.2904 4.79744 14.2301
        4.94303C14.1698 5.08862 14.1388 5.24466 14.1388 5.40225C14.1388 5.55983 14.1698
        5.71588 14.2301 5.86147C14.2904 6.00706 14.3788 6.13934 14.4902 6.25077C14.6017 6.3622
        14.7339 6.4506 14.8795 6.5109C15.0251 6.57121 15.1812 6.60225 15.3387 6.60225C15.657
        6.60225 15.9622 6.47582 16.1873 6.25077C16.4123 6.02573 16.5387 5.72051 16.5387
        5.40225C16.5387 5.08399 16.4123 4.77876 16.1873 4.55372C15.9622 4.32867 15.657
        4.20225 15.3387 4.20225ZM10.0013 5.60475C9.32009 5.59412 8.64362 5.7191 8.01122
        5.97242C7.37883 6.22574 6.80314 6.60233 6.31769 7.08028C5.83223 7.55822 5.4467
        8.12795 5.18355 8.75632C4.9204 9.38469 4.78488 10.0591 4.78488 10.7404C4.78488 11.4216
        4.9204 12.0961 5.18355 12.7244C5.4467 13.3528 5.83223 13.9225 6.31769 14.4005C6.80314
        14.8784 7.37883 15.255 8.01122 15.5083C8.64362 15.7616 9.32009 15.8866 10.0013
        15.876C11.3494 15.855 12.6353 15.3046 13.5812 14.3438C14.5272 13.383 15.0574 12.0887
        15.0574 10.7404C15.0574 9.39203 14.5272 8.09776 13.5812 7.13693C12.6353 6.1761
        11.3494 5.62578 10.0013 5.60475ZM10.0013 7.406C10.8854 7.406 11.7334 7.75723 12.3586
        8.38243C12.9838 9.00763 13.335 9.85558 13.335 10.7397C13.335 11.6239 12.9838 12.4719
        12.3586 13.0971C11.7334 13.7223 10.8854 14.0735 10.0013 14.0735C9.11709 14.0735 8.26913
        13.7223 7.64393 13.0971C7.01873 12.4719 6.6675 11.6239 6.6675 10.7397C6.6675 9.85558
        7.01873 9.00763 7.64393 8.38243C8.26913 7.75723 9.11709 7.406 10.0013 7.406Z"
      fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class instagram extends Vue {}
</script>
