import Vue from 'vue';
import VueRouter from 'vue-router';
import CampaignsList from '@/apps/film_owner_dashboard/views/CampaignsList.vue';
import CampaignDetails from '@/apps/film_owner_dashboard/views/CampaignDetails.vue';
import PlanningDetails from '@/apps/film_owner_dashboard/views/PlanningDetails.vue';
import CampaignSettings from '@/apps/film_owner_dashboard/views/CampaignSettings.vue';
import FilmOwnerDashboard from '@/apps/film_owner_dashboard/FilmOwnerDashboard.vue';
import CinemaOwnerDashboard from '@/apps/cinema_owner_dashboard/CinemaOwnerDashboard.vue';
import Index from '@/apps/landing/Index.vue';
import PlanningsList from '@/apps/cinema_owner_dashboard/views/PlanningsList.vue';
import PlanningDetailsCinema from '@/apps/cinema_owner_dashboard/views/PlanningDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  // {
  //   path: '/cinema_manager_dashboard',
  //   name: 'CinemaOwnerDashboard',
  //   component: CinemaOwnerDashboard,
  //   children: [{
  //     path: 'plannings/list',
  //     name: 'CinemaOwnerPlanningsList',
  //     component: PlanningsList,
  //   },
  //   {
  //     path: 'plannings/detail/:reference',
  //     name: 'CinemaOwnerPlanningDetails',
  //     component: PlanningDetailsCinema,
  //   },
  //   ],
  // },
  // {
  //   path: '/film_owner_dashboard',
  //   name: 'FilmOwnerDashboard',
  //   component: FilmOwnerDashboard,
  //   children: [{
  //     path: 'campaigns/list',
  //     name: 'CampaignsList',
  //     component: CampaignsList,
  //   },
  //   {
  //     path: 'campaigns/detail/:reference',
  //     name: 'CampaignDetails',
  //     component: CampaignDetails,
  //   },
  //   {
  //     path: 'negotiation/planning/:reference',
  //     name: 'Planning',
  //     component: PlanningDetails,
  //   },
  //   {
  //     path: 'campaigns/settings/:reference',
  //     name: 'Settings',
  //     component: CampaignSettings,
  //   },
  //   {
  //     path: 'campaigns/settings/',
  //     name: 'SettingsNewCampaign',
  //     component: CampaignSettings,
  //   },
  //   ],
  // },
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
