<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.993 0C3.129 0 0 3.12597 0 6.9776C0 10.8292 3.129 13.9552 6.993 13.9552C10.864 13.9552
14 10.8292 14 6.9776C14 3.12597 10.864 0 6.993 0ZM7 12.5597C3.906 12.5597 1.4 10.0617
1.4 6.9776C1.4 3.8935 3.906 1.39552 7 1.39552C10.094 1.39552 12.6 3.8935 12.6 6.9776C12.6 10.0617
10.094 12.5597 7 12.5597ZM7.35 3.4888H6.3V7.67536L9.975 9.87331L10.5 9.01506L7.35 7.15204V3.4888Z"
      fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class clock extends Vue {}
</script>
