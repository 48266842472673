<template>
<svg width="10" height="21" viewBox="0 0 10 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.78422 12.0289C2.71462 12.0289 1.18329 12.0289 0.487239 12.0289C0.116009 12.0289
0 11.8787 0 11.5032C0 10.5019 0 9.47567 0 8.47441C0 8.07391 0.139211 7.94876 0.487239
7.94876H2.78422C2.78422 7.87366 2.78422 6.42185 2.78422 5.746C2.78422 4.74475 2.94664
3.79356 3.41067 2.91747C3.89791 2.01634 4.59397 1.41559 5.47564 1.06515C6.05569 0.839871
6.63573 0.739746 7.26218 0.739746H9.53596C9.86079 0.739746 10 0.889934 10 1.24037V4.09394C10
4.44438 9.86079 4.59456 9.53596 4.59456C8.90951 4.59456 8.28306 4.59456 7.65661 4.6196C7.03016
4.6196 6.70534 4.945 6.70534 5.64588C6.68214 6.39682 6.70534 7.12272 6.70534
7.89869H9.39675C9.76798 7.89869 9.90719 8.04888 9.90719 8.44938V11.4782C9.90719 11.8787
9.79118 12.0038 9.39675 12.0038C8.56149 12.0038 6.77494 12.0038 6.70534 12.0038V20.164C6.70534
20.5896 6.58933 20.7397 6.17169 20.7397C5.19722 20.7397 4.24594 20.7397 3.27146 20.7397C2.92343
20.7397 2.78422 20.5896 2.78422 20.2141C2.78422 17.5858 2.78422 12.104 2.78422 12.0289Z"
      fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class facebook extends Vue {}
</script>
