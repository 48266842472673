<template>
<svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 2.7505C21.175 3.08998 20.3225 3.325 19.415 3.42945C20.35 2.90718 21.065
2.07154 21.395 1.05311C20.515 1.54927 19.5525 1.88875 18.535 2.09766C17.71 1.26202
16.5275 0.739746 15.235 0.739746C12.7325 0.739746 10.725 2.67216 10.725 5.02239C10.725 5.36187
10.7525 5.67523 10.835 5.98859C7.095 5.83191 3.795 4.10841 1.5675 1.52316C-0.0275001
4.23898 1.76 6.48475 2.9425 7.24205C2.2275 7.24205 1.5125 7.03314 0.9075 6.71978C0.9075
8.83498 2.475 10.5846 4.51 10.9763C4.07 11.1069 3.08 11.1852 2.475 11.0546C3.0525 12.752
4.73 14.0055 6.6825 14.0316C5.1425 15.1806 2.8875 16.0946 0 15.8073C2.0075 17.0347 4.3725
17.7397 6.93 17.7397C15.235 17.7397 19.745 11.2113 19.745 5.57078C19.745 5.38798 19.745
5.20518 19.7175 5.02239C20.6525 4.36955 21.4225 3.61225 22 2.7505Z" fill="currentColor"/>
<path d="M22 2.7505C21.175 3.08998 20.3225 3.325 19.415 3.42945C20.35 2.90718 21.065 2.07154
21.395 1.05311C20.515 1.54927 19.5525 1.88875 18.535 2.09766C17.71 1.26202 16.5275 0.739746
15.235 0.739746C12.7325 0.739746 10.725 2.67216 10.725 5.02239C10.725 5.36187 10.7525 5.67523
10.835 5.98859C7.095 5.83191 3.795 4.10841 1.5675 1.52316C-0.0275001 4.23898 1.76 6.48475
2.9425 7.24205C2.2275 7.24205 1.5125 7.03314 0.9075 6.71978C0.9075 8.83498 2.475 10.5846 4.51
10.9763C4.07 11.1069 3.08 11.1852 2.475 11.0546C3.0525 12.752 4.73 14.0055 6.6825 14.0316C5.1425
15.1806 2.8875 16.0946 0 15.8073C2.0075 17.0347 4.3725 17.7397 6.93 17.7397C15.235 17.7397
19.745 11.2113 19.745 5.57078C19.745 5.38798 19.745 5.20518 19.7175 5.02239C20.6525 4.36955
21.4225 3.61225 22 2.7505Z" fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class twitter extends Vue {}
</script>
