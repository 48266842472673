



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StatisticCard extends Vue {
  @Prop() title!:string;

  @Prop({ default: false }) editMode!:boolean;
}
