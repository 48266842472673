import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import objectSupport from 'dayjs/plugin/objectSupport';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const tz = Intl.DateTimeFormat().resolvedOptions().locale;
import(`dayjs/locale/${tz}.js`).then(() => {
  dayjs.locale(tz);
});
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);
dayjs.extend(localizedFormat);

export default dayjs;
