<template>
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.9994 20.238V12.9497C19.9994 9.3678 19.2315 6.63159 15.0702 6.63159C13.0638
6.63159 11.7263 7.72607 11.1813 8.77081H11.1318V6.95496H7.19336V20.238H11.3052V13.6462C11.3052
11.905 11.6272 10.2384 13.7574 10.2384C15.8628 10.2384 15.8876 12.2035 15.8876
13.7457V20.2131H19.9994V20.238Z" fill="currentColor"/>
<path d="M0.791016 6.63159H4.7929V20.238H0.791016V6.63159Z" fill="currentColor"/>
<path d="M2.83375 0.227539C1.50812 0.227539 0.432617 1.29747 0.432617 2.61623C0.432617 3.93499
1.50812 5.0298 2.83375 5.0298C4.15937 5.0298 5.23488 3.93499 5.23488 2.61623C5.23488 1.29747
4.15937 0.227539 2.83375 0.227539Z" fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class linkedin extends Vue {}
</script>
