



























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import iTbs from '@/assets/landing/i_tbs.png';
import onDemand from '@/assets/landing/on_demand.png';
import iTds from '@/assets/landing/i_tds.png';
import logo from '@/assets/landing/movieday_top_logo.png';
import cinemaAnimation from '@/assets/landing/cinema_illustration.png';
import iconLogo from '@/assets/landing/movieday_icon_logo.png';

@Component
export default class Index extends Vue {
  email: string = 'info@movieday.it';

  logo: string = logo;

  drawer: boolean = false;

  cinemaAnimation: string = cinemaAnimation;

  title: string = 'Everyday is a Movieday';

  subtitle: string = 'La potenza della tecnologia, la \n meraviglia del cinema.';

  iconLogo: string = iconLogo;

  company: string = '2021 © Movieday NEXT srl P.IVA: 10224290964';

  appBarMenu: Array<{
    title: string;
    link: string;
    icon: string;
    menu?: Array<{ title: string; link: string; icon: string }>;
  }> = [
      {
        title: 'Per distributori',
        link: 'https://about.movieday.it/intelligent-theatrical-distribution-system/',
        icon: '$vuetify.custom.ticket',
      },
      {
        title: 'Per promoter',
        link: 'https://about.movieday.it/on-demand/',
        icon: '$vuetify.custom.megaphone',
      },
      {
        title: 'Per esercenti',
        link: 'https://about.movieday.it/i-tbs-intelligent-theater-booking-system/',
        icon: 'calendar_today',
      },
    ];

  productCards: Array<{
    title: string;
    subtitle: string;
    description: string;
    icon: string;
    link: string;
  }> = [
      {
        title: 'PER DISTRIBUTORI',
        subtitle: 'I-TDS',
        description: 'Digitalizza e potenzia l’intero processo distributivo',
        icon: '$vuetify.custom.ticket',
        link: 'https://about.movieday.it/intelligent-theatrical-distribution-system/',
      },
      {
        title: 'PER PROMOTER',
        subtitle: 'ON-DEMAND',
        description: 'Promuovi la tua causa con il potere del cinema',
        icon: '$vuetify.custom.megaphone',
        link: 'https://about.movieday.it/on-demand/',
      },
      {
        title: 'PER ESERCENTI',
        subtitle: 'I-TBS',
        description: 'Multiprogramma e fatti pagare le tue sale',
        link: 'https://about.movieday.it/i-tbs-intelligent-theater-booking-system/',
        icon: 'calendar_today',
      },
    ];

  productSections: Array<{
    title: string;
    subTitle: string;
    video: string;
    videoAlt: string;
    ctaButton: { title: string; link: string };
    discoverButton: { title: string; link: string };
    paragraphs: Array<{
      keyword: string;
      description: string;
      comingSoon?: string;
    }>;
  }> = [
      {
        title: 'Pianifica, distribuisci e vendi i biglietti',
        subTitle: 'intelligent theatrical distribution system',
        video: iTds,
        videoAlt: 'Demo I-TDS',
        ctaButton: {
          title: 'PRENOTA UNA DEMO',
          link: 'https://about.movieday.it/prenota-una-demo-i-tds/',
        },
        discoverButton: {
          title: 'Scopri I-TDS',
          link: 'https://about.movieday.it/intelligent-theatrical-distribution-system/',
        },
        paragraphs: [
          {
            keyword: 'Planning',
            description:
              'In base agli obiettivi della tua campagna, il sistema ti offre la '
              + 'programmazione ottimale di proiezioni con il massimo ritorno sull’investimento.',
          },
          {
            keyword: 'Distribution',
            description:
              'Con un solo click lanci la programmazione ai cinema target, registri gli '
              + 'accordi commerciali, fatturi gli incassi e misuri le performance. ',
          },
          {
            keyword: 'Direct-ticketing',
            description:
              'La prima biglietteria al mondo pensata per i distributori, per moltiplicare '
              + 'il tasso di conversione e acquisire customer base. ',
            comingSoon: ' coming soon',
          },
        ],
      },
      {
        title: 'Organizza proiezioni speciali per la tua comunità',
        subTitle: 'cinema on-demand',
        video: onDemand,
        videoAlt: 'Demo on-Demand',
        ctaButton: { title: 'CREA UN EVENTO', link: '/event/creation/flux' },
        discoverButton: { title: 'Scopri ON-DEMAND', link: 'https://about.movieday.it/on-demand/' },
        paragraphs: [
          {
            keyword: 'Organizza',
            description:
              'Bastano pochi click per selezionare il film, il luogo della proiezione al '
              + 'Cinema o Personale, inserire la descrizione, il giorno e l’ora.',
          },
          {
            keyword: 'Promuovi',
            description:
              'Diffondi la pagina dell’evento sui tuoi canali per invitare le persone a '
              + 'prenotare. ',
          },
          {
            keyword: 'Condividi',
            description: 'Fai vivere un’esperienza unica che i partecipanti ameranno',
          },
        ],
      },
      {
        title: 'Metti a rendita le tue sale, aumenta guadagni, film e pubblico',
        subTitle: 'intelligent theater booking system',
        video: iTbs,
        videoAlt: 'Demo I-TBS',
        ctaButton: {
          title: 'REGISTRA IL TUO  CINEMA',
          link: 'https://about.movieday.it/prenota-una-demo-i-tbs/',
        },
        discoverButton: {
          title: 'Scopri I-TBS',
          link: 'https://about.movieday.it/i-tbs-intelligent-theater-booking-system/',
        },
        paragraphs: [
          {
            keyword: 'Calcola',
            description:
              'Inserisci i costi gestionali del tuo cinema e il sistema ti imposterà i prezzi '
              + 'e le percentuali ottimali per ogni slot di programmazione. ',
          },
          {
            keyword: 'Programma',
            description:
              'Verifica e contratta le proposte di programmazione che ricevi per creare un '
              + 'palinsesto di qualità e remunerativo. ',
          },
          {
            keyword: 'Guadagna',
            description:
              'Qualunque sia il risultato del botteghino, incassa sempre il tuo minimo '
              + 'garantito per sostenere i tuoi costi.',
          },
        ],
      },
    ];

  contactBox: {
    title: string;
    demoButton: { title: string; link: string };
    contactButton: { title: string; link: string };
  } = {
      title: 'Sfrutta la rivoluzione digitale del cinema.',
      demoButton: { title: 'Richiedi una demo', link: 'https://about.movieday.it/prenota-una-demo/' },
      contactButton: { title: 'Contattaci', link: 'https://about.movieday.it/contatta-movieday/' },
    };

  footerData: Array<{
    title: string;
    links: Array<{ title: string; link: string }>;
  }> = [
      {
        title: 'Company',
        links: [
          {
            title: 'Chi siamo',
            link: 'https://about.movieday.it/',
          },
          {
            title: 'Perché Movieday',
            link: 'https://about.movieday.it/perche-movieday/',
          },
          {
            title: 'Sogna con noi',
            link: 'https://about.movieday.it/sogna-con-noi/',
          },
        ],
      },
      {
        title: 'Products',
        links: [
          {
            title: 'I-TDS (per distributori)',
            link: 'https://about.movieday.it/intelligent-theatrical-distribution-system/',
          },
          {
            title: 'ON-DEMAND (per promoter)',
            link: 'https://about.movieday.it/on-demand/',
          },
          {
            title: 'I-TBS (per esercenti)',
            link: 'https://about.movieday.it/i-tbs-intelligent-theater-booking-system/',
          },
        ],
      },
      {
        title: 'Cerca',
        links: [
          {
            title: 'Film',
            link: '/movie_listing/index/all_movies',
          },
          {
            title: 'Eventi',
            link: '/events/event_listing',
          },
          {
            title: 'Cinema',
            link: '/cinema/cinema_listing',
          },
        ],
      },
    ];

  socials: Array<{ icon: string; link: string }> = [
    { icon: '$vuetify.custom.facebook', link: 'https://www.facebook.com/moviedaymarketplace' },
    { icon: '$vuetify.custom.instagram', link: 'https://www.instagram.com/movieday_marketplace/' },
    { icon: '$vuetify.custom.linkedin', link: 'https://www.linkedin.com/company/movieday/' },
    { icon: '$vuetify.custom.twitter', link: 'https://twitter.com/Movieday_Italia' },
  ];

  copyright: { title: string; link: string } = {
    title: 'Termini di servizio',
    link: 'https://about.movieday.it/termini-di-servizio/',
  };

  legalNotes: { title: string; link: string } = {
    title: 'Privacy',
    link: 'https://about.movieday.it/privacy/',
  };

  privacy: { title: string; link: string } = {
    title: 'Policy acquisto',
    link: 'https://about.movieday.it/policy-di-acquisto/',
  };

  get isMobile() {
    return this.$isMobile;
  }
}
