





































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StatisticSection extends Vue {
  @Prop({ default: false }) editMode!: boolean;

  @Prop() eventsNumber!: number;

  @Prop() screeningNumber!: number;

  @Prop() boxOfficeTarget!: number;

  @Prop() guaranteedMinimum!: number;

  @Prop() boxOfficeShare!: number;

  economics: string = 'Economics';

  events: string = 'Eventi';

  bos: string = 'Box Office';

  gm: string = 'Minimo Garantito';

  gmChanged(gm:number) {
    this.$emit('gmChange', gm);
  }

  bosChanged(bos:number) {
    this.$emit('bosChange', bos);
  }
}
