<template>
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.1 1.40846V6.33805H4.9V1.40846H9.1ZM9.1 0H4.9C4.13 0 3.5 0.633805 3.5
1.40846V7.7465H10.5V1.40846C10.5 0.633805 9.87 0 9.1 0ZM14 4.92959H11.9V7.04228H14V4.92959ZM2.1
4.92959H0V7.04228H2.1V4.92959ZM12.6 8.45073H1.4V12.6761H2.8V9.85919H11.2V12.6761H12.6V8.45073Z"
      fill="currentColor"/>
</svg>

</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class loungeChair extends Vue {}
</script>
