
































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class btn extends Vue {
  @Prop() text!: string

  @Prop({ default: 'primary' }) type!: 'primary' | 'white' | 'outlined' | 'grey'

  @Prop({ default: false }) disabled!: boolean

  get color() {
    if (this.type === 'white') return 'black';
    return 'white';
  }

  get btnClass() {
    if (this.type === 'white') return 'white rounded-lg mr-1';
    return 'primary rounded-lg';
  }

  clicked() {
    this.$emit('click');
  }
}
