


















































































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class inputField extends Vue {
  @Prop({ default: 'textField' }) type!: 'textField' |
    'select' | 'slider' | 'date' | 'simpleSelect'

  @Prop({ default: 3 }) cols!: number

  @Prop({ default: '' }) value!: string | number

  @Prop() items?: Array<{ id: string, name: string, disabled: boolean }>

  @Prop() valueType?: 'string' | 'number'

  @Prop() icon?: string

  @Prop() title!: string

  @Prop() max?: number

  @Prop({ default: false }) disabled!: boolean

  infoIcon: string = '$vuetify.custom.infoOutline'

  menuSwitcher: boolean = false;

  datePickerModel: Array<string> = [];

  changed(value: any) {
    this.$emit('change', value);
  }
}
