<template>
<svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M14.0069 1.31035C14.1609 1.5145 14.3149 1.71865 14.4689
  1.92089C13.9519 2.3091 13.3429 2.7605 12.7738 3.18224L12.7738
  3.18226C12.5038 3.38238 12.2428 3.57582 12.0049 3.75266C11.8509
  3.5504 11.6969 3.34624 11.5429 3.14207L11.5428 3.142C11.3889 2.93785
  11.2349 2.73371 11.0809 2.53148L11.8499 1.95938C12.4189 1.53593 13.0279
  1.08272 13.5449 0.699707C13.6989 0.901961 13.8529 1.10612 14.0069 1.31028L14.0069
  1.31035ZM12.6208 6.04268V7.56916H15.7008V6.04268H12.6208ZM11.0809 11.0873C11.6047
  11.4713 12.2408 11.9435 12.8382 12.3871L12.8383 12.3871L12.8409 12.3891L12.8447
  12.3919C13.088 12.5725 13.3248 12.7484 13.5449 12.9114C13.6989 12.7092 13.8529
  12.505 14.0069 12.3008C14.1609 12.0967 14.3149 11.8925 14.4689 11.6903C13.952
  11.3073 13.343 10.8541 12.774 10.4307L12.0049 9.8585C11.6969 10.2706 11.3889
  10.6828 11.0809 11.0873ZM1.84079 4.51657C0.993785 4.51657 0.300781 5.20348 0.300781
   6.04304V7.56952C0.300781 8.40908 0.993785 9.09599 1.84079
   9.09599H2.61079V12.1489H4.1508V9.09599H4.9208L8.77082 11.3857V2.22686L4.9208
   4.51657H1.84079ZM5.71391 5.8217L7.23081 4.92108V8.69148L5.71391 7.79086L5.34431
   7.56952H4.9208H1.84079V6.04304H4.9208H5.34431L5.71391 5.8217ZM9.54066 4.24893C10.2491
   4.87478 10.6957 5.79066 10.6957 6.80576C10.6957 7.82086 10.2491 8.73674 9.54066 9.35497V4.24893Z"
  fill="currentColor"
/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class megaphone extends Vue {}
</script>
