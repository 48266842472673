<template>
<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 0C3.636 0 0.5 3.136 0.5 7C0.5 10.864 3.636 14 7.5 14C11.364 14 14.5 10.864 14.5
7C14.5 3.136 11.364 0 7.5 0ZM7.5 12.6C4.413 12.6 1.9 10.087 1.9 7C1.9 3.913 4.413 1.4 7.5
1.4C10.587 1.4 13.1 3.913 13.1 7C13.1 10.087 10.587 12.6 7.5 12.6ZM8.123 6.37C6.877 5.957
6.275 5.698 6.275 5.04C6.275 4.326 7.052 4.067 7.542 4.067C8.459 4.067 8.795 4.76 8.872
5.005L9.978 4.536C9.873 4.228 9.404 3.199 8.116 2.975V2.1H6.891V2.982C5.071 3.374 5.057
4.977 5.057 5.054C5.057 6.643 6.632 7.091 7.402 7.371C8.508 7.763 8.998 8.12 8.998 8.792C8.998
 9.583 8.263 9.919 7.612 9.919C6.338 9.919 5.974 8.61 5.932 8.456L4.77 8.925C5.211 10.458 6.366
 10.871 6.884 10.997V11.9H8.109V11.032C8.473 10.969 10.223 10.619 10.223 8.778C10.23 7.805
 9.803 6.951 8.123 6.37Z" fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class dollar extends Vue {}
</script>
