





































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

type State = 'draft' | 'proposal' | 'counterproposal' | 'accepted' | 'refused' | 'expired';

type Planning = {
  reference: string,
   // eslint-disable-next-line camelcase
  cinema_owner_ref: string,
   // eslint-disable-next-line camelcase
  film_owner_ref: string,
   // eslint-disable-next-line camelcase
  campaign_ref: string,
   // eslint-disable-next-line camelcase
  editor_id: string | null,
  state: State,
   // eslint-disable-next-line camelcase
  box_office_share: number,
   // eslint-disable-next-line camelcase
  guaranteed_minimum: number,
   // eslint-disable-next-line camelcase
  screenings_number:number,
   // eslint-disable-next-line camelcase
  screenings_accepted:number,
}

// eslint-disable-next-line no-unused-vars
type PlanningsByState = {[key in State]: Array<Planning>}

@Component
export default class planningsList extends Vue {
  @Prop() plannings!: Array<any>;

  @Prop({ default: 'Programmazioni' }) title!:string;

  @Prop({ default: false }) cinemaMode!:boolean;

  get linkPath() {
    return (this.cinemaMode
      ? '/cinema_manager_dashboard/plannings/detail/'
      : '/film_owner_dashboard/negotiation/planning/');
  }

  get planningsForStatus() {
    return this.plannings.reduce(
      (planningsByState, planning) => {
        planningsByState[planning.state].push(planning);
        return planningsByState;
      }, {
        draft: [],
        proposal: [],
        counterproposal: [],
        accepted: [],
        refused: [],
        expired: [],
      } as PlanningsByState,
    );
  }
}

