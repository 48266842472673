









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

interface info {
  text:string,
  color:string,
  altText?:string,
}

@Component
export default class StatusSection extends Vue {
  @Prop({ default: 'draft' }) planningState!: string;

  @Prop() boxOfficeShare!: number;

  @Prop() guaranteedMinimum!: number;

  @Prop() proposalBoxOfficeShare!: number;

  @Prop() proposalGuaranteedMinimum!: number;

  @Prop() cinema?: any;

  boxOfficeShareText = 'Percentuale:'

  guaranteedMinimumText = 'Minimo garantito:'

  status:{draft: info,
    accepted: info,
    refused: info,
    proposal: info,
    pending: info,
    expired: info, } =
    {
      draft: {
        text: 'questo è il planning generato dall\'algoritmo. Puoi modificarlo o inviarlo.',
        color: 'warning',
      },
      accepted: {
        text: 'Il planning è stato accettato. Puoi ancora aggiungere degli screenings.',
        color: 'success',
      },
      refused: {
        text: 'Il planning è stato rifiutato.',
        color: 'error',
      },
      proposal: {
        text: 'Sono state apportate queste modifiche:',
        altText: 'Non sono state apportate modifiche rispetto alle richieste del Cinema.',
        color: 'info',
      },
      pending: {
        text: 'Hai inviato il planning. In attesa di una risposta.',
        color: 'warning',
      },
      expired: {
        text: 'Il planning é scaduto. Non hai ricevuto risposta entro la data di inizio proiezioni..',
        color: 'error',
      },
    }

  get infoDelta() {
    return (this.planningState === 'proposal') && (this.deltaBos || this.deltaGm);
  }

  get deltaBos() {
    return (this.boxOfficeShare - this.proposalBoxOfficeShare !== 0);
  }

  get deltaGm() {
    return (this.guaranteedMinimum - this.proposalGuaranteedMinimum !== 0);
  }
}
