













import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class backbutton extends Vue {
  clicked() {
    this.$emit('click');
  }
}
