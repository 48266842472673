import '@/router/componentHooks'; // patch vue-router in order to expose hooks
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue';
import App from '@/App.vue';
import './scss/main.scss';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import '@/plugins/isMobile';
import '@/plugins/moviedayCustom';

if (process.env.WEBAPP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.WEBAPP_SENTRY_DSN,
    environment: process.env.APP_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost:8080', 'dev.movieday.it', 'movieday.it', /^\//],
      }),
    ],
    tracesSampleRate: 0.01,
    autoSessionTracking: true,
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
