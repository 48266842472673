


























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class AddScreeningTab extends Vue {
  @Prop({ default: 'Aggiungi' }) button!: string;

  @Prop({ default: false }) disableCalendar!: boolean;

  @Prop({ default: '' }) start!: string;

  @Prop() hallsAvailability!: Array<any>;

  @Prop() filmDuration!: number;

  @Prop() halls!: Array<any>;

  @Prop({ default: 9 }) ticketPrice!: number;

  @Prop({ default: 20 }) guaranteedMinimum!: number;

  @Prop({ default: false }) disableConfirmButton!: boolean;

  events = [];

  hallRef = ''

  selectedStart = ''

  selectedEnd = ''

  selectedTicket: number | null = null

  infoIcon = '$vuetify.custom.infoOutline';

  calendarDialog = false;

  newScreeningTime: string = this.start;

  get mdColumnWidth() {
    if (this.disableConfirmButton) {
      return 4;
    }
    return 3;
  }

  addScreening() {
    this.$emit('fulfilledScreening');
  }

  actionConfirm() {
    this.$emit('actionConfirmed', {
      start: this.selectedStart,
      end: this.selectedEnd,
      hallRef: this.hallRef,
      ticket: this.selectedTicket || this.ticketPrice,
    });
  }

  close() {
    this.$emit('closed');
  }

  ticketChange(ticket: number) {
    this.selectedTicket = ticket;
    this.$emit('ticketChanged', ticket);
  }

  gmChange(gm: number) {
    this.$emit('gmChanged', gm);
  }

  closeCalendarDialog() {
    this.calendarDialog = false;
  }

  updateNewScreeningTime(payload: any) {
    this.hallRef = payload.hall.reference;
    this.selectedStart = payload.start;
    this.selectedEnd = payload.end;
    this.newScreeningTime = payload.hall.name.concat(payload.start);
  }

  calendarViewChanged(payload: { start: string; end: string }) {
    this.$emit('rangeUpdated', payload);
  }
}
