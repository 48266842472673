
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ResumeSection extends Vue {
  @Prop() readonly filmTitle!: string;

  @Prop() readonly boxOfficeTarget!: string;

  @Prop() readonly startDate!: string;

  @Prop() readonly endDate!: string;
}
