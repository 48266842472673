import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import it from 'vuetify/src/locale/it';
import megaphone from '@/assets/custom_icons/megaphone.vue';
import ticket from '@/assets/custom_icons/ticket.vue';
import instagram from '@/assets/custom_icons/instagram.vue';
import facebook from '@/assets/custom_icons/facebook.vue';
import linkedin from '@/assets/custom_icons/linkedin.vue';
import twitter from '@/assets/custom_icons/twitter.vue';
import ticketPercent from '@/assets/custom_icons/ticketPercent.vue';
import ticketPrice from '@/assets/custom_icons/ticketPrice.vue';
import clock from '@/assets/custom_icons/clock.vue';
import loungeChair from '@/assets/custom_icons/loungeChair.vue';
import dollar from '@/assets/custom_icons/dollar.vue';
import infoOutline from '@/assets/custom_icons/infoOutline.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      infoOutline: {
        component: infoOutline,
      },
      megaphone: {
        component: megaphone,
      },
      instagram: {
        component: instagram,
      },
      facebook: {
        component: facebook,
      },
      linkedin: {
        component: linkedin,
      },
      twitter: {
        component: twitter,
      },
      ticket: {
        component: ticket,
      },
      ticketPercent: {
        component: ticketPercent,
      },
      ticketPrice: {
        component: ticketPrice,
      },
      clock: {
        component: clock,
      },
      loungeChair: {
        component: loungeChair,
      },
      dollar: {
        component: dollar,
      },
    },
  },
  lang: {
    locales: { it },
    current: 'it',
  },
  theme: {
    themes: {
      light: {
        primary: '#FC3855',
        secondary: '#E60D41',
        accent: '#0056D7',
        error: '#FC686F',
        info: '#47E6EC',
        success: '#53F198',
        warning: '#F5D52C',
      },
    },
  },
});
