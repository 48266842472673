<template>
<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0671 3.6001V1.26676C12.0671 0.625098 11.5421 0.100098 10.9004
0.100098H1.56706C0.925391 0.100098 0.406224 0.625098 0.406224 1.26676V3.6001C1.04789
3.6001 1.56706 4.1251 1.56706 4.76676C1.56706 5.40843 1.04789 5.93343 0.400391
5.93343V8.26676C0.400391 8.90843 0.925391 9.43343 1.56706 9.43343H10.9004C11.5421 9.43343 12.0671
8.90843 12.0671 8.26676V5.93343C11.4254 5.93343 10.9004 5.40843 10.9004 4.76676C10.9004 4.1251
11.4254 3.6001 12.0671 3.6001ZM10.9004 2.74843C10.2062 3.15093 9.73372 3.90926 9.73372
4.76676C9.73372 5.62426 10.2062 6.3826 10.9004 6.7851V8.26676H1.56706V6.7851C2.26122
6.3826 2.73372 5.62426 2.73372 4.76676C2.73372 3.90343 2.26706 3.15093 1.57289 2.74843L1.56706
1.26676H10.9004V2.74843ZM4.52456 7.1001L6.23372 6.00343L7.94289 7.1001L7.42372 5.1401L8.99289
3.85676L6.96872 3.73426L6.23372 1.8501L5.49289 3.72843L3.46872 3.85093L5.03789
5.13426L4.52456 7.1001Z" fill="currentColor"/>
</svg>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ticket extends Vue {}
</script>
